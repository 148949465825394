import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

export class ApiGuiaMassagemService {
  protected urlApi = environment.apiUrl;
  protected endpoint!: string;

  constructor(protected http: HttpClient) {}

  setEndpoint(endpoint: string): void {
    this.endpoint = endpoint;
  }

  get url(): string {
    return `${this.urlApi}/${this.endpoint}`;
  }
}
